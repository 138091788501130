.products-list-product .description {
  position: relative;
  font-size: $products-product-description-font-size;

  .text {
    overflow: hidden;
    font-size: $products-list-product-description-font-size;
    font-weight: $products-list-product-description-font-weight;
    padding-top: $products-list-product-description-padding-top;
    line-height: $products-list-product-description-line-height;
    color: $products-list-product-description-color;

    a {
      font-size: $products-list-product-description-font-size;
      font-weight: $products-list-product-description-font-weight;
      line-height: $products-list-product-description-line-height;
      background: $panel-background-color;
      color: $products-list-product-description-link-color;
      text-decoration: underline;
    }

    &.truncate {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .link {
    position: absolute;
    text-decoration: underline;
    font-size: $products-list-product-description-font-size;
    font-weight: $products-list-product-description-font-weight;
    line-height: $products-list-product-description-line-height;
    background: $products-list-product-description-background;
    color: $products-list-product-description-link-color;
    bottom: 0;
    right: 0;

    &:before {
      position: absolute;
      content: "...";
      margin-left: -9px;
      color: $products-list-product-description-color;
      background:  $panel-background-color;
    }
  }
}