.approvals-list-item-prior-approvals {
  @include media-breakpoint-up(lg) {
    background: $approvals-list-item-prior-approvals-background-lg;
    margin-top: $approvals-list-item-prior-approvals-lg-margin-top;
    margin-left: $approvals-list-item-prior-approvals-lg-margin-left;
  }

  .no-approvals {
    padding: $approvals-list-item-prior-approvals-no-approvals-padding;
    font-weight: bold;
  }
}