.approvals-list {
  font-size: 80%;

  @include media-breakpoint-up(md)  {
    padding-bottom: $spacer * 2;
  }

  .column-name {
    min-width: $approvals-list-column-name-width;
    width: $approvals-list-column-name-width;
    max-width: $approvals-list-column-name-width;
    padding: $approvals-list-column-padding;
    word-break: break-word;
  }

  .column-order {
    min-width: $approvals-list-column-order-width;
    width: $approvals-list-column-order-width;
    max-width: $approvals-list-column-order-width;
    padding: $approvals-list-column-padding;
    word-break: break-word;
  }

  .column-details {
    min-width: $approvals-list-column-details-width;
    width: 100%;
    max-width: 100%;
    padding: $approvals-list-column-padding;
    word-break: break-word;
  }

  .column-editable {
    min-width: $approvals-list-column-editable-width;
    width: $approvals-list-column-editable-width;
    max-width: $approvals-list-column-editable-width;
    flex-basis:$approvals-list-column-editable-width;
    padding: $approvals-list-column-padding;
  }

  .column-cta {
    min-width: $approvals-list-column-cta-width;
    width: $approvals-list-column-cta-width;
    max-width: $approvals-list-column-cta-width;
    padding: $approvals-list-column-padding;

    textarea {
      width: $approvals-list-column-cta-width * 0.65
    }
  }
}