.categories-list-category {
  display: $categories-list-category-display;
  flex-direction: $categories-list-category-flex-direction;
  background: $categories-list-category-background;

  .thumbnail {
    order: $categories-list-category-thumbnail-order;
    margin-bottom: $categories-list-category-thumbnail-margin-bottom;
    box-shadow: $categories-list-category-thumbnail-box-shadow;

    .image {
      width: $categories-list-category-thumbnail-image-width;
      max-width: $categories-list-category-thumbnail-image-width;
      min-width: $categories-list-category-thumbnail-image-width;
      border-radius: $categories-list-category-thumbnail-image-border-radius;
      box-shadow: $categories-list-category-thumbnail-image-drop-shadow;
    }
  }

  .title {
    font-size: $categories-list-category-title-font-size;
    line-height: $categories-list-category-title-line-height;
    color: $categories-list-category-title-color;
    font-weight: $categories-list-category-title-font-weight;
    text-align: $categories-list-category-title-text-align;
    order: $categories-list-category-title-order;
    margin: $categories-list-category-title-margin;
    background: $categories-list-category-title-background;
  }

  .cta {
    order: $categories-list-category-cta-order;
    text-align: $categories-list-category-cta-text-align;

    .icon {
      font-size: $categories-list-category-cta-icon-font-size;
    }
  }
}