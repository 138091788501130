// .categories-list-category .title
$categories-list-category-title-order: 1;
$categories-list-category-title-margin: 0 0 $spacer/2 0;
$categories-list-category-title-background: #e9e0f7;
$categories-list-category-title-font-weight: bold;
$categories-list-category-title-color: #5f249f;
$categories-list-category-title-margin: 0 unset 8px 0;

// .categories-list-category .thumbnail
$categories-list-category-thumbnail-box-shadow: none;

// .categories-list-category .image
$categories-list-category-thumbnail-order: 2;
$categories-list-category-thumbnail-margin-bottom: 0;

[data-skin="dxc"] {
  .categories-list-category {
    border-radius: 12px;
    box-shadow: $panel-box-shadow;
    padding: $spacer;
    margin-bottom: 12px;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: fit-content;
      height: 20px;
      padding: 2px 8px;
      font-size: 10px !important;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: .2px;
      border-radius: 20px;
      position: initial;

      @include hover-focus-active() {
        text-decoration: none;
      }
    }

    .thumbnail {
      padding-bottom: 30px;
      position: relative;
    }
  }
}