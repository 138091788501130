.cart-review-order-button {
  @include media-breakpoint-down(xs) {
    margin: 1rem 1rem 0;

    > .btn {
      display: block;
      min-width: 100%;
      max-width: 100%;
      width: 100%;
      text-align: center;
    }
  }
  @include media-breakpoint-up(sm) {
    position: absolute;
    top: $cart-review-order-button-top;
    right: $cart-review-order-button-right;
  }
}