.views-order-submit .product {
  padding-bottom: $views-order-submit-product-padding-bottom;

  & + & {
    margin-bottom: $views-order-submit-product__plus-margin-top;
    border-bottom: $views-order-submit-product__plus-border-top;
  }

  .image {
    width: 100%;
    min-width: $views-order-submit-product-image-width;
    max-width: $views-order-submit-product-image-width;
    border: $views-order-submit-product-image-border;
    border-radius: $views-order-submit-product-image-border-radius;

    @include media-breakpoint-down(xs) {
      min-width: $views-order-submit-product-image__xs-width;
      max-width: $views-order-submit-product-image__xs-width;
    }
  }

  .name {
    font-weight: $views-order-submit-product-name-font-weight;
    font-size: $views-order-submit-product-name-font-size;
    line-height: $views-order-submit-product-name-line-height;
  }

  .quantity-control {
    margin-top: $spacer;

    input {
      text-align: center;
      height: 40px;
      max-width: 80px;

      &[disabled] {
        background: $form-control-background !important;
      }
    }

    button {
      height: 40px;
    }
  }

  .btn-remove {
    color: $views-order-submit-product-btn-remove-color;
    text-transform: $views-order-submit-product-btn-text-transform;
    @each $property, $value in $views-order-submit-product-btn-remove-paddings {
      padding-#{$property}: $value;
    }
  }
}